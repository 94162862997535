import { GET } from "../api";
import { SEARCH_CATEGORIES_WITH_COUNT } from "../../constants/api";
// import { SOMETHING_WRONG } from "../../lang/en/messages";

// import store from "../../store";
// import { receiveCategories } from "../../actions";

export const search = async (query = {}, callback, seller_id) => {
	// elastic search data = query = {}
	let UPDATED_SEARCH_CATEGORIES_WITH_COUNT = seller_id
		? `${SEARCH_CATEGORIES_WITH_COUNT}${`/${seller_id}`}`
		: SEARCH_CATEGORIES_WITH_COUNT;
	GET(UPDATED_SEARCH_CATEGORIES_WITH_COUNT, function (data) {
		if (data.status && data.status) {
			// will convert this to recursion if required more deep hierarchy
			const categories = data.data && data.data;
			//   let sorted_categories = {};
			let sorted_categories = [];
			categories &&
				categories.forEach((parent, i) => {
					if (!parent.parent_id && parent.is_active) {
						let sub_categories = [];
						categories.forEach((child, i) => {
							if (child.parent_id === parent.id) {
								sub_categories.push(child);
							}
						});

						// sorted for fast search result
						// sorted_categories = {
						//   ...sorted_categories,
						//   ...{
						//     [parent.id]: {
						//       id: parent.id,
						//       name: parent.name,
						//       description: parent.description,
						//       sub_categories: sub_categories,
						//       has_items: parent.has_items,
						//       parent_id: parent.parent_id,
						//       is_active: parent.is_active,
						//     },
						//   },
						// };

						sorted_categories.push({
							id: parent.id,
							name: parent.name,
							description: parent.description,
							meta_title: parent.meta_title,
							meta_description: parent.meta_description,
							sub_categories: sub_categories,
							has_items: parent.has_items,
							parent_id: parent.parent_id,
							heading: parent.heading,
							is_active: parent.is_active,
							items_count: parent.items_count,
						});
					}
				});

			callback(sorted_categories);
			// for now, it is just for add product form, later will change it and connect with redux for replacing the current one
			// store.dispatch(receiveCategories(sorted_categories));
		} else {
			if (data.http_error) {
				// toast.error(data.error);
			} else {
				if (data.errors) {
					for (let i = 0; i < data.errors.length; i++) {
						// toast.error(data.errors[i]);
					}
				} else {
					//   toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
