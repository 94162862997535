import { POST } from "../api";
import { SEARCH_PRODUCT } from "../../constants/api";
// import { SOMETHING_WRONG } from "../../lang/en/messages";

export const productSearch = async (query = {}, callback) => {
	const data = {
		fields: [
			"id",
			"uuid",
			"owner_id",
			"dynamic_fields",
			"name",
			"description",
			"condition",
			"category_id",
			"brand_id",
			"price",
			"is_taxable",
			"cost",
			"discount",
			"discount_unit",
			"age",
			"age_unit",
			"moq",
			"total_qty",
			"um",
			"msrp",
			"mpn",
			"weight",
			"weight_unit",
			"length",
			"length_unit",
			"width",
			"width_unit",
			"height",
			"height_unit",
			"package_type",
			"upc",
			"part_number",
			"sku",
			"last_sku",
			"type",
			"shipping_type",
			"package_type",
			"publish_date",
			"is_active",
			"reviews_total",
			"reviews_avg",
			"options",
			"scalling_price",
			"variants",
			"created_at",
			"meta_name",
			"meta_description",
		],
		with: ["images", "documents", "category", "brand", "owner", "bulk_savings"],
		where: ["is_active", 1],
	};

	if (typeof query !== "undefined" && query.length > 0) {
		if (typeof query.price !== "undefined" && query.price.length > 0) {
			query.where = [
				["price", ">=", query.price.min],
				["price", "<=", query.price.max],
			];
		}
		data.where_in = [];
		if (typeof query.categories !== "undefined" && query.categories.length > 0) {
			data.where_in.push({
				field: "category_id",
				value: query.categories,
			});
		}
		if (typeof query.brands !== "undefined" && query.brands.length > 0) {
			data.where_in.push({
				field: "brand_id",
				value: query.brands,
			});
		}
		if (typeof query.sortby !== "undefined" && query.sortby === "date") {
			data.orderby = {
				created_at: "DESC",
				name: "ASC",
			};
		} else if (typeof query.sortby !== "undefined" && query.sortby === "rating") {
			data.orderby = {
				reviews_avg: "DESC",
				name: "ASC",
			};
		} else {
			data.orderby = {
				sale_count: "DESC",
				name: "ASC",
			};
		}
		if (typeof query.search !== "undefined" && query.search !== "") {
			data.query = {
				value: "*" + query.search + "*",
				fields: ["name"],
			};
		}
	}
	POST(SEARCH_PRODUCT, data, function (data) {
		if (data.status && data.status) {
			const products = [];
			for (let i = 0; i < data.data.length; i++) {
				const product = data.data[i];
				let salePrice = product.price;
				let moq = product.moq;
				let bulkSavings = [];
				let condition = product.condition.charAt(0).toUpperCase() + product.condition.slice(1);

				if (product.scalling_price) {
					bulkSavings = product.bulk_savings;
				}

				if (product.discount && product.discount_unit === "percent") {
					salePrice = product.price - (product.price / 100) * product.discount;
				} else if (product.discount && product.discount_unit === "fixed") {
					salePrice = product.price - product.discount;
				}
				products.push({
					id: product.id,
					name: product.name,
					price: product.price,
					pictures: product.images,
					images: product.images,
					dynamic_fields: product.dynamic_fields,
					imagePlaceholder: "assets/images/products/shop/sm-1.jpg",
					shortDesc: product.description,
					stock: product.total_qty,
					category: [product.category ? product.category.name : ""],
					brands: [product.brand ? product.brand.name : ""],
					ratings: product.reviews_total,
					reviews: product.reviews_total,
					featured: true,
					age: product.age,
					age_unit: product.age_unit,
					brand_id: product.brand_id,
					category_id: product.category_id,
					main_category: (product.category && product.category.parent_id) || 0,
					condition: condition,
					cost: product.cost,
					created_at: product.created_at,
					deleted_at: product.deleted_at,
					salePrice: salePrice,
					discount_unit: product.discount_unit,
					discount: product.discount,
					height: product.height,
					height_unit: product.height_unit,
					is_active: product.is_active,
					is_taxable: product.is_taxable,
					last_sku: product.last_sku,
					length: product.length,
					length_unit: product.length_unit,
					moq: moq,
					mpn: product.mpn,
					msrp: product.msrp,
					options: product.options,
					owner_id: product.owner_id,
					package_type: product.package_type,
					part_number: product.part_number,
					publish_date: product.publish_date,
					reviews_avg: product.reviews_avg,
					short_name: product.short_name,
					sku: product.sku,
					tags: product.tags,
					type: product.type,
					um: product.um,
					upc: product.upc,
					shipping_type: product.shipping_type,
					updated_at: product.updated_at,
					uuid: product.uuid,
					variants: product.variant,
					weight: product.weight,
					weight_unit: product.weight_unit,
					width: product.width,
					scalling_price: product.scalling_price,
					width_unit: product.width_unit,
					owner: product.owner,
					documents: product.documents,
					bulk_savings: bulkSavings,
					meta_name: product.meta_name,
					meta_description: product.meta_description,
				});
			}
			const result = products && products.filter((product) => product.is_active !== false);
			callback(result);
		} else {
			if (data.http_error) {
				// toast.error(data.error);
			} else {
				if (data.errors) {
					for (let i = 0; i < data.errors.length; i++) {
						// toast.error(data.errors[i]);
					}
				} else {
					//   toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
