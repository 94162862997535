import { GET_DEAL_LISTING_SIDEBAR } from "../../constants/api";
import ApiService from "../api-service";

const getDealListingSidebar = async (category) => {
	let url = GET_DEAL_LISTING_SIDEBAR;

	if (category !== undefined && category !== null && category !== "") {
		url += `?category=${encodeURIComponent(category)}`;
	}

	const response = await ApiService.get(url);

	if (response && response.success) {
		let data = response.data;
		return data;
	}

	throw new Error("Deal Listing sidebar not found!");
};

export default getDealListingSidebar;
