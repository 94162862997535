import { POST } from "../api";
import { CLIENT_FORGOT_PASSWORD } from "../../constants/api";
import { SOMETHING_WRONG } from "../../lang/en/messages";
import { toast } from "react-toastify";
export const forgotPassword = (userData) => {
	POST(CLIENT_FORGOT_PASSWORD, userData, function (data) {
		if (data.status && data.status) {
			toast.success("Verification code has been sent to the given email."); // not let it break
		} else {
			if (data.http_error) {
				toast.error(data.error);
			} else {
				if (data.errors) {
					for (let i = 0; i < data.errors.length; i++) {
						toast.error(data.errors[i]);
					}
				} else {
					toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
