import { POST } from "../api";
import { CLIENT_REGISTER } from "../../constants/api";

export const register = (data, toast, callback) => {
	POST(CLIENT_REGISTER, data, function (data) {
		if (data.status && data.status) {
			callback(data.status);
			// toast.success(data.message || ""); // not let it break
		} else {
			callback(data.status);
			if (data.http_error) {
				toast.error(data.error);
			} else {
				if (data.errors.email) {
					for (let i = 0; i < data.errors.email.length; i++) {
						toast.error(data.errors.email[i]);
					}
				} else if (data.errors.password) {
					for (let i = 0; i < data.errors.password.length; i++) {
						toast.error(data.errors.password[i]);
					}
				} else {
					toast.error("Registration failed");
				}
			}
		}
	});
};
