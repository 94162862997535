import { RECEIVE_CATEGORIES, RECEIVE_SELLER_CATEGORIES, REMOVE_SELLER_CATEGORIES } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	categories: [],
	sellerCategories: [],
};

const categoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_CATEGORIES:
			return {
				...state,
				categories: action.categories,
			};
		case RECEIVE_SELLER_CATEGORIES:
			return {
				...state,
				sellerCategories: action.categories,
			};
		case REMOVE_SELLER_CATEGORIES:
			return {
				...state,
				sellerCategories: initialState.sellerCategories,
			};
		default:
			return state;
	}
};
const persistConfig = {
	keyPrefix: "cat-",
	key: "categories",
	storage,
};

export default persistReducer(persistConfig, categoryReducer);
