import React, { memo } from "react";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "../../../constants/regex";
import Error from "../../helper/input-field-error";
import { BUYER as message } from "../../../lang/en/messages";

const Register = memo(({ onSubmit }) => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-group">
				<label>First Name *</label>
				<input
					type="text"
					className={`form-control ${errors.firstname && "is-invalid"}`}
					{...register("firstname", { required: true, maxLength: 100, pattern: /^[A-Za-z]+$/i })}
				/>
				{errors.firstname && errors.firstname.type === "pattern" && (
					<Error message="Special or numeric characters are not allowed" />
				)}
				{errors.firstname && errors.firstname.type !== "pattern" && <Error message={message.register.first_name.req} />}
			</div>

			<div className="form-group">
				<label>Last Name *</label>
				<input
					type="text"
					className={`form-control ${errors.lastname && "is-invalid"}`}
					{...register("lastname", { required: true, maxLength: 100, pattern: /^[A-Za-z]+$/i })}
				/>
				{errors.lastname && errors.lastname.type === "pattern" && (
					<Error message="Special or numeric characters are not allowed" />
				)}
				{errors.lastname && errors.lastname.type !== "pattern" && <Error message={message.register.last_name.req} />}
			</div>

			<div className="form-group">
				<label>Your email address *</label>
				<input
					type="email"
					className={`form-control ${errors.email && "is-invalid"}`}
					{...register("email", {
						required: true,
						maxLength: 100,
						pattern: EMAIL_REGEX,
					})}
				/>
				{errors.email && <Error message={message.register.email.reqvalid} />}
			</div>

			<div className="form-group">
				<label>Password *</label>
				<input
					type="password"
					className={`form-control ${errors.password && "is-invalid"}`}
					{...register("password", { required: true, minLength: 8 })}
				/>
				{errors.password && <Error message={message.register.password.reqvalid} />}
			</div>

			<div className="form-group">
				<label>Confirm Password *</label>
				<input
					type="password"
					className={`form-control ${errors.password_confirmation && "is-invalid"}`}
					{...register("password_confirmation", {
						required: true,
						validate: (value) => value === watch("password"),
					})}
				/>
				{errors.password_confirmation && <Error message={message.register.confirm_password.match} />}
			</div>

			<div className="form-footer">
				<button
					type="submit"
					className="btn btn-outline-primary-2">
					<span>SIGN UP</span>
					<i className="icon-long-arrow-right"></i>
				</button>
			</div>
		</form>
	);
});

export default Register;
