import { RECEIVE_BRANDS, RECEIVE_SELLER_BRANDS, REMOVE_SELLER_BRANDS } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	brands: [],
	sellerBrands: [],
};

const brandsReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_BRANDS:
			return {
				...state,
				brands: action.brands,
			};
		case RECEIVE_SELLER_BRANDS:
			return {
				...state,
				sellerBrands: action.sellerBrands,
			};
		case REMOVE_SELLER_BRANDS:
			return {
				...state,
				sellerBrands: initialState.sellerBrands,
			};
		default:
			return state;
	}
};
const persistConfig = {
	keyPrefix: "brand-",
	key: "brands",
	storage,
};

export default persistReducer(persistConfig, brandsReducer);
