import { TITLE_REGEX, WHITE_SPACES } from "../../../constants/regex";

export const BASE = process.env.PUBLIC_URL;
// export const HOME = `${BASE}/`;

//deals listings
export const DEALS_LISTING_PAGE = `${BASE}/trader/find`;

// broker seller chat
export const SELLER_CHAT_PAGE = `${BASE}/trader/chat`;

// Broker Landing page Route
export const BROKER_LANDING_PAGE = `${BASE}/trader/buyer`;
export const BROKER_LANDING_PAGE_SELLER = `${BASE}/trader/seller`;

//deals Detail Page
export const DEAL_DETAIL_PAGE = `${BASE}/trader/deal`;
export const DEAL_LISTING_PAGE = `${BASE}/trader/find`;

//generaters
export const DEAL_DETAIL_URL = (name, id) =>
	`${DEAL_DETAIL_PAGE}/${name && name.replace(TITLE_REGEX, " ").trim().replace(WHITE_SPACES, "-").toLowerCase()}-${id}`;
