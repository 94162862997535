import { GET_SELLER_BRANDS } from "../../../constants/api";
import { GET } from "../../api";

// import { SOMETHING_WRONG } from "../../lang/en/messages";

// import store from "../../store";
// import { receiveCategories } from "../../actions";

export const getSellerBrands = async (query = {}, seller_id, callback) => {
	let UPDATED_GET_SELLER_BRANDS = `${GET_SELLER_BRANDS}?seller_id=${seller_id}`;
	GET(UPDATED_GET_SELLER_BRANDS, function (data) {
		if (data.status && data.status) {
			const brands =
				data.data &&
				data.data.map((brand, index) => {
					const {
						id,
						logo,
						name,
						heading,
						meta_title,
						meta_description,
						created_by,
						status,
						created_at,
						updated_at,
						items_count,
					} = brand;
					return {
						id: id,
						logo: logo,
						name: name,
						heading: heading,
						meta_title: meta_title,
						meta_description: meta_description,
						created_by: created_by,
						status: status,
						created_at: created_at,
						updated_at: updated_at,
						items_count: items_count,
					};
				});
			callback(brands);
		} else {
			if (data.http_error) {
				// toast.error(data.error);
			} else {
				if (data.errors) {
					for (let i = 0; i < data.errors.length; i++) {
						// toast.error(data.errors[i]);
					}
				} else {
					//   toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
