// reducer.js

import { SET_IS_GUEST_USER } from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	isGuestUser: false,
	// other state variables
};

const GuestUser = (state = initialState, action) => {
	console.log(action.type);
	switch (action.type) {
		case SET_IS_GUEST_USER:
			return {
				...state,
				isGuestUser: action.payload,
			};
		// handle other actions
		default:
			return state;
	}
};

const persistConfig = {
	keyPrefix: "molla-",
	key: "GuestUser",
	storage,
};

export default persistReducer(persistConfig, GuestUser);
