import { POST } from "../api";
import { SEARCH_BRANDS } from "../../constants/api";
// import { SOMETHING_WRONG } from "../../lang/en/messages";

export const getBrands = async (query = {}, callback) => {
	POST(SEARCH_BRANDS, query, function (data) {
		if (data.status && data.status) {
			const brands = data.data && data.data;
			const result = brands && brands.filter((brand) => brand.status !== "inactive");
			callback(result);
		} else {
			if (data.http_error) {
				// toast.error(data.error);
			} else {
				if (data.errors) {
					for (let i = 0; i < data.errors.length; i++) {
						// toast.error(data.errors[i]);
					}
				} else {
					//   toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
