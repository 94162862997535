import {
	EDIT_PROJECT,
	RECEIVE_STORE,
	RECEIVE_STORE_FRONT_FAV_LIST,
	REMOVE_EDIT_PROJECT,
	REMOVE_STORE,
	UPDATE_STORE,
} from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	fav_list: [],
	store: { loading: true },
	projects: {
		selectedProject: {},
	},
};

const storeFrontReducer = (state = initialState, action) => {
	switch (action.type) {
		case EDIT_PROJECT:
			return {
				...state,
				projects: {
					selectedProject: action.selectedProject,
				},
			};
		case RECEIVE_STORE_FRONT_FAV_LIST:
			return {
				...state,
				fav_list: action.store,
			};
		case REMOVE_EDIT_PROJECT:
			return {
				...state,
				projects: {
					selectedProject: {},
				},
			};
		case RECEIVE_STORE:
			return {
				...state,
				store: action.store,
			};
		case UPDATE_STORE:
			return {
				...state,
				store: { ...state.store, ...action.data },
			};
		case REMOVE_STORE:
			return {
				...state,
				store: initialState.store,
			};
		default:
			return state;
	}
};

const persistConfig = {
	keyPrefix: "StoreFront-",
	key: "StoreFront",
	storage,
};

export default persistReducer(persistConfig, storeFrontReducer);
