import { POST, GET } from "../api";
import { CLIENT_SIGNIN, CLIENT_PROFILE } from "../../constants/api";
import { USER, USER_PROFILE } from "../../constants/local-storage";
import { SOMETHING_WRONG, IN_ACTIVE_USER } from "../../lang/en/messages";
import { asyncLocalStorage } from "../../utils";
import { ACTIVE } from "../../constants/app";
import store from "../../store";
import { setIsGuestUser, userLogin, userProfile } from "../../actions";
import { GA_login } from "../../components/features/googleAnalytics/index";

export const signin = (data, toast, callback) => {
	// Get isGuestUser from the Redux store
	const isGuestUser = store.getState().GuestUser.isGuestUser;

	// Get GuestId from local storage and convert it to a number
	const guestId = Number(localStorage.getItem("GuestId")) || ""; // Convert guestId to a number

	// Add new keys to data object
	const modifiedData = {
		...data,
		isGuest: isGuestUser, // Add isGuestUser from Redux store
		guestId, // Add guestId from local storage, now numeric
	};

	// Make the POST request with the modified data
	POST(CLIENT_SIGNIN, modifiedData, function (response) {
		if (response.status && response.status) {
			toast.success("Logged in successfully");
			GET(
				CLIENT_PROFILE,
				(profile) => {
					const status = profile.status && profile.data && profile.data.status;
					if (status === ACTIVE) {
						asyncLocalStorage.setItem(USER, JSON.stringify(response.data)).then(() => {
							store.dispatch(userLogin());
							GA_login();
							callback(response);
							// toast.success(response.message || ""); // not let it break
						});
						asyncLocalStorage.setItem(USER_PROFILE, JSON.stringify(profile.data)).then(() => {
							store.dispatch(userProfile());
						});
						localStorage.setItem("isGuestUser", false);
						localStorage.removeItem("GuestToken");
						localStorage.removeItem("GuestId");

						store.dispatch(setIsGuestUser(false));
					} else {
						toast.error(IN_ACTIVE_USER);
					}
				},
				response.data.auth && response.data.auth.access_token
			);
		} else {
			if (response.http_error) {
				callback(false);
				toast.error(response.error);
			} else if (parseInt(response.code) === 403) {
				callback(response);
			} else {
				if (response.errors) {
					callback(false);
					for (let i = 0; i < response.errors.length; i++) {
						toast.error(response.errors[i]);
					}
				} else {
					callback(false);
					toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
