import { ADD_EDIT_PRODUCT, REMOVE_PRODUCT_DETAILS } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	product_details: null,
};

const addEditProductReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_EDIT_PRODUCT:
			return {
				...state,
				product_details: action.product_details,
			};
		case REMOVE_PRODUCT_DETAILS:
			return {
				...initialState,
			};
		default:
			return state;
	}
};
const persistConfig = {
	keyPrefix: "add-edit-product-",
	key: "add-edit-product",
	storage,
};

export default persistReducer(persistConfig, addEditProductReducer);
