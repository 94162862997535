import * as types from "../constants/action-types";
import { findIndex } from "../utils";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	value: { min: 0, max: 10000 },
	sortBy: "quantity, high to low",
	category: [],
	main_category: [],
	size: [],
	color: [],
	brand: [],
	rating: [],
	shippingType: [],
	searchString: "",
	condition: [],
};

function filterReducer(state = initialState, action) {
	let index;
	switch (action.type) {
		case types.SET_FILTER:
			return {
				...initialState,
				main_category: action.payload.categoryId ? [action.payload.categoryId] : initialState.main_category,
				category: action.payload.subcategory,
				searchString: action.payload.searchString ? action.payload.searchString : initialState.searchString,
				condition: action.payload.condition,
				shippingType: action.payload.shippingType,
				brand: action.payload.brand,
				sortBy: action.payload.sortBy,
			};
		case types.SORT_BY:
			return {
				...state,
				sortBy: action.sortBy,
			};

		// case types.CATEGORY_FILTER:

		//     let category = Object.assign( [], state.category );
		//     let index = findIndex( category, item => item === action.category.toLowerCase() );
		//     if ( -1 === index ) {
		//         category.push( action.category.toLowerCase() );
		//     } else {
		//         category.splice( index, 1 );
		//     }
		//     return {
		//         ...state,
		//         category: category
		//     };

		// case types.MAIN_CATEGORY_FILTER:

		//     let main_category = Object.assign( [], state.main_category );
		//     index = findIndex( main_category, item => item === action.main_category );

		//     if ( -1 === index ) {
		//         main_category=[action.main_category];
		//     } else {
		//         main_category.splice( index, 1 );
		//     }
		//     //main_category = [action.main_category];

		//     return {
		// 						...state,
		//         value: { min: 0, max: 10000 },
		//         sortBy:state.sortBy,
		//         category: [],
		//         main_category: main_category,
		//         size: [],
		//         color: [],
		//         brand: [],
		//         rating: [],
		//         shippingType:[],
		//         searchString: "",
		//     };

		case types.RESET_FILTER:
			return {
				...state,
				value: { min: 0, max: 10000 },
				sortBy: "quantity, high to low",
				category: [],
				main_category: [],
				size: [],
				color: [],
				brand: [],
				rating: [],
				shippingType: [],
				searchString: "",
				condition: [],
			};

		case types.SIZE_FILTER:
			let size = Object.assign([], state.size);
			index = findIndex(size, (item) => item === action.size);
			if (-1 === index) {
				size.push(action.size);
			} else {
				size.splice(index, 1);
			}
			return {
				...state,
				size: size,
			};

		case types.COLOR_FILTER:
			let color = Object.assign([], state.color);
			index = findIndex(color, (item) => item === action.color);
			if (-1 === index) {
				color.push(action.color);
			} else {
				color.splice(index, 1);
			}
			return {
				...state,
				color: color,
			};

		case types.BRAND_FILTER:
			let brand = Object.assign([], state.brand);
			index = findIndex(brand, (item) => item === action.brand);
			if (-1 === index) {
				brand.push(action.brand);
			} else {
				brand.splice(index, 1);
			}
			return {
				...state,
				brand: brand,
			};

		case types.SHIPPING_TYPE_FILTER:
			let shippingType = Object.assign([], state.shippingType);
			index = findIndex(shippingType, (item) => item === action.shippingType);
			if (-1 === index) {
				shippingType.push(action.shippingType);
			} else {
				shippingType.splice(index, 1);
			}
			return {
				...state,
				shippingType: shippingType,
			};

		case types.CONDITION_FILTER:
			let condition = Object.assign([], state.condition);
			index = findIndex(condition, (item) => item.toLowerCase() === action.condition.toLowerCase());
			if (-1 === index) {
				condition.push(action.condition);
			} else {
				condition.splice(index, 1);
			}
			return {
				...state,
				condition: condition,
			};

		case types.RATING_FILTER:
			let rating = Object.assign([], state.rating);
			index = findIndex(rating, (item) => item === action.rating);
			if (-1 === index) {
				rating.push(action.rating);
			} else {
				rating.splice(index, 1);
			}
			return {
				...state,
				rating: rating,
			};

		case types.PRICE_FILTER:
			return {
				...state,
				value: action.range,
			};

		case types.SEARCH_STRING_FILTER:
			return {
				...state,
				searchString: action.searchString,
			};

		default:
			return state;
	}
}

const persistConfig = {
	keyPrefix: "molla-",
	key: "filter",
	storage,
};

export default persistReducer(persistConfig, filterReducer);
