import { GET } from "../api";
import { DOMAIN_URL, GET_STORE } from "../../constants/api";
import { toast } from "react-toastify";
import { SOMETHING_WRONG } from "../../lang/en/messages";

export const getStore = async (seller_id, editAble, callback, token = null, query = null) => {
	let UPDATED_GET_STORE = GET_STORE;
	//search string
	if (query) {
		let queryString = "";
		if (query.search && query.search !== "") {
			// eslint-disable-next-line no-useless-concat
			if (editAble) {
				queryString = `fields=["id","user_id","name","title","email","website","phone","uuid"]&query={"value": ${`+*``${query.search.replace(
					/\s+/g,
					"* +*"
				)}*`},"fields": ["title"]},"where": [],`;
				UPDATED_GET_STORE = `${UPDATED_GET_STORE}?${queryString}`;
			} else {
				queryString = `fields=["id","user_id","name","title","email","website","phone","uuid"]&query={"value": ${`+*``${query.search.replace(
					/\s+/g,
					"* +*"
				)}*`},"fields": ["title"]},"where": [["is_enable",1]],`;
				UPDATED_GET_STORE = `${UPDATED_GET_STORE}?${queryString}`;
			}
		}
	} else {
		if (editAble) {
			UPDATED_GET_STORE = `${UPDATED_GET_STORE}?where=[["user_id",${seller_id}]]&fields=["id","user_id","name","email","website","phone","address","zip","title","subtitle","about","company_vision","shipping_refund","seller_detail_services","seller_detail_coverage_area","profile_images","background_images","uuid"]&with=["seller_detail_services","seller_detail_coverage_area","profile_images","background_images"]`;
		} else {
			UPDATED_GET_STORE = `${UPDATED_GET_STORE}?where=[["user_id",${seller_id}],["is_enable",1]]&fields=["id","user_id","name","email","website","phone","address","zip","title","subtitle","about","company_vision","shipping_refund","seller_detail_services","seller_detail_coverage_area","profile_images","background_images","uuid"]&with=["seller_detail_services","seller_detail_coverage_area","profile_images","background_images"]`;
		}
	}
	GET(
		UPDATED_GET_STORE,
		function (data) {
			if (data.status === 302) {
				// Redirect to the desired URL
				const redirectUrl = `${DOMAIN_URL}storefront-listing`;
				if (redirectUrl) {
					toast.error("Storefront not found.");
					window.location.href = redirectUrl;
				} else {
					toast.error("Redirection URL not found.");
				}
			} else if (!!data.status) {
				callback(data.data);
			} else {
				if (data.http_error) {
					toast.error(data.error);
				} else {
					if (data.errors) {
						toast.error(data.errors[0]);
					} else {
						toast.error(SOMETHING_WRONG);
					}
				}
			}
		},
		token
	);
};
