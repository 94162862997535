import { findIndex } from "../../../../utils";
export function setPathParams(data, amend, match, baseUrl, splitter = "/") {
	const url = new URL(window.location);
	let newURL = ``;
	newURL = baseUrl;
	if (amend === "reset") {
	} else {
		let category = [];
		let subcategory = [];
		let brand = [];
		let condition = [];
		let shippingType = [];
		let panelWattage = [];
		let color = [];
		let intercoms = [];
		let state = [];
		let moqUnit = [];
		let companyTiers = [];
		//for merging old filter implementaion parameters (will change later)
		if (match && (match.url.includes("/shop/brands") || match.url.includes("/shop/product"))) {
			match.params.subcategory && subcategory.push(`sb_${match.params.subcategory}`);
			match.params.category && category.push(`ca_${match.params.category}`);
			match.params.brand && brand.push(`br_${match.params.brand}`);
			match.params.condition && condition.push(`br_${match.params.condition}`);
		}
		//getting old url parameters
		let preParams = window.location.pathname.split(splitter);
		preParams.forEach((item) => {
			if (item.startsWith("ca_")) {
				category.push(item);
			}
			if (item.startsWith("sb_")) {
				subcategory.push(item);
			}
			if (item.startsWith("br_")) {
				brand.push(item);
			}
			if (item.startsWith("cd_")) {
				condition.push(item);
			}
			if (item.startsWith("st_")) {
				shippingType.push(item);
			}
			if (item.startsWith("pw_")) {
				panelWattage.push(item);
			}
			if (item.startsWith("co_")) {
				color.push(item);
			}
			if (item.startsWith("ic_")) {
				intercoms.push(item);
			}
			if (item.startsWith("sta_")) {
				state.push(item);
			}
			if (item.startsWith("mu_")) {
				moqUnit.push(item);
			}
			if (item.startsWith("ct_")) {
				companyTiers.push(item);
			}
		});
		//setting subcategory
		if (data.subcategory && amend) {
			let index = findIndex(subcategory, (item) => item === `sb_${data.subcategory}`);
			if (-1 === index) {
				subcategory.push(`sb_${data.subcategory}`);
			} else {
				subcategory.splice(index, 1);
			}
		} else if (data.subcategory && !amend) {
			subcategory = [`sb_${data.subcategory}`];
		}
		//setting condition
		if (data.condition === "resetCondition") {
			condition = [];
		} else if (data.condition && amend) {
			let index = findIndex(condition, (item) => item.toLowerCase() === `cd_${data.condition}`.toLowerCase());
			if (-1 === index) {
				condition.push(`cd_${data.condition}`);
			} else {
				condition.splice(index, 1);
			}
		} else if (data.condition && !amend) {
			condition = [`cd_${data.condition}`];
		}
		//setting shippingType
		if (data.shippingType && amend) {
			let index = findIndex(shippingType, (item) => item === `st_${data.shippingType}`);
			if (-1 === index) {
				shippingType.push(`st_${data.shippingType}`);
			} else {
				shippingType.splice(index, 1);
			}
		} else if (data.shippingType && !amend) {
			shippingType = [`st_${data.shippingType}`];
		}
		//setting brand
		if (data.brand && amend) {
			let index = findIndex(brand, (item) => item === `br_${data.brand}`);
			if (-1 === index) {
				brand.push(`br_${data.brand}`);
			} else {
				brand.splice(index, 1);
			}
		} else if (data.brand && !amend) {
			brand = [`br_${data.brand}`];
		}
		//setting Pannel Wattage
		if (data.panelWattage && amend) {
			let index = findIndex(panelWattage, (item) => item === `pw_${data.panelWattage}`);
			if (-1 === index) {
				panelWattage = [`pw_${data.panelWattage}`];
			} else {
				panelWattage.splice(index, 1);
			}
		} else if (data.panelWattage && !amend) {
			panelWattage = [`pw_${data.panelWattage}`];
		}
		//setting Color
		if (data.color && amend) {
			let index = findIndex(color, (item) => item === `co_${data.color}`);
			if (-1 === index) {
				color.push(`co_${data.color}`);
			} else {
				color.splice(index, 1);
			}
		} else if (data.color && !amend) {
			color = [`co_${data.color}`];
		}
		//setting state
		if (data.state && amend) {
			let index = findIndex(state, (item) => item === `sta_${data.state}`);
			if (-1 === index) {
				state.push(`sta_${data.state}`);
			} else {
				state.splice(index, 1);
			}
		} else if (data.state && !amend) {
			state = [`sta_${data.state}`];
		}
		//setting Intercoms
		if (data.intercom && amend) {
			let index = findIndex(intercoms, (item) => item === `ic_${data.intercom}`);
			if (-1 === index) {
				intercoms.push(`ic_${data.intercom}`);
			} else {
				intercoms.splice(index, 1);
			}
		} else if (data.intercom && !amend) {
			intercoms = [`ic_${data.intercom}`];
		}
		//setting moq unit
		if (data.moqUnit && amend) {
			let index = findIndex(moqUnit, (item) => item === `mu_${data.moqUnit}`);
			if (-1 === index) {
				moqUnit.push(`mu_${data.moqUnit}`);
			} else {
				moqUnit.splice(index, 1);
			}
		} else if (data.moqUnit && !amend) {
			moqUnit = [`mu_${data.moqUnit}`];
		}
		//setting search string
		if (data.searchString === "resetSearchString") {
			url.searchParams.delete("q");
		} else if (data.searchString) {
			url.searchParams.set("q", data.searchString);
		}
		if (data.sort_by) {
			url.searchParams.set("sort_by", data.sort_by);
		}
		if (data.page_no) {
			url.searchParams.set("page_no", data.page_no);
		} else {
			url.searchParams.delete("page_no");
		}
		if (data.per_page) {
			url.searchParams.set("per_page", data.per_page);
		}
		//setting companyTiers
		if (data.companyTier && amend) {
			let index = findIndex(companyTiers, (item) => item === `ct_${data.companyTier}`);
			if (-1 === index) {
				companyTiers.push(`ct_${data.companyTier}`);
			} else {
				companyTiers.splice(index, 1);
			}
		} else if (data.companyTier && !amend) {
			companyTiers = [`ct_${data.companyTier}`];
		}
		//setting category
		if (data.category && amend) {
			let index = data.category !== "all" && findIndex(category, (item) => item === `ca_${data.category}`);
			if (-1 === index) {
				category = [`ca_${data.category}`];
			} else {
				category = [];
			}
			subcategory = [];
			// brand = [];
			// condition = [];
			// shippingType = [];
			url.searchParams.delete("page_no");
			// url.searchParams.delete('per_page');
		} else if (data.category && !amend) {
			category = [`ca_${data.category}`];
		}
		// adding category to newUrl
		if (category.length > 0) {
			newURL = `${newURL}${splitter}${category.join("/")}`;
		}
		// adding subcategory to newUrl
		if (subcategory.length > 0) {
			newURL = `${newURL}${splitter}${subcategory.join("/")}`;
		}
		// adding condition to newUrl
		if (condition.length > 0) {
			newURL = `${newURL}${splitter}${condition.join("/")}`;
		}
		// adding shippingType to newUrl
		if (shippingType.length > 0) {
			newURL = `${newURL}${splitter}${shippingType.join("/")}`;
		}
		// adding brand to newUrl
		if (brand.length > 0) {
			newURL = `${newURL}${splitter}${brand.join("/")}`;
		}
		// adding panel Wattage to newUrl
		if (panelWattage.length > 0) {
			newURL = `${newURL}${splitter}${panelWattage.join("/")}`;
		}
		// adding color to newUrl
		if (color.length > 0) {
			newURL = `${newURL}${splitter}${color.join("/")}`;
		}
		// adding intercoms to newUrl
		if (intercoms.length > 0) {
			newURL = `${newURL}${splitter}${intercoms.join("/")}`;
		}
		// adding intercoms to newUrl
		if (state.length > 0) {
			newURL = `${newURL}${splitter}${state.join("/")}`;
		}
		// adding moq unit to newUrl
		if (moqUnit.length > 0) {
			newURL = `${newURL}${splitter}${moqUnit.join("/")}`;
		}
		// adding companyTiers to newUrl
		if (companyTiers.length > 0) {
			newURL = `${newURL}${splitter}${companyTiers.join("/")}`;
		}
		//adding query parameters
		newURL = `${newURL}?${url.searchParams}`.toLowerCase();
	}
	return newURL;
}
