import { POST } from "../api";
import { SERACH_STORE } from "../../constants/api";

// feature store
export const filterStore = async (query = {}, callback, token = null) => {
	const data = {
		fields: [
			"id",
			"user_id",
			"name",
			"title",
			"subtitle",
			"about",
			"company_vision",
			"uuid",
			"is_featured_storefront",
			"storefront_priority",
		],
		with: ["profile_images"],
		where: [["storefront_priority", "!=", null]],
		orderby: {
			storefront_priority: "ASC",
		},
		limit: 6,
	};

	POST(
		SERACH_STORE,
		data,
		function (data) {
			if (data.status && data.status) {
				callback(data.data);
			} else {
				callback(false);
			}
		},
		token
	);
};
