import { POST } from "../api";
import { SERACH_STORE } from "../../constants/api";

export const searchStore = async (query = {}, callback, token = null) => {
	const data = {
		fields: ["id", "user_id", "name", "title", "subtitle", "email", "about", "company_vision", "uuid"],
		with: ["profile_images"],
		where_has: {
			user: {
				where: [["status", "active"]],
			},
		},
		limit: 6,
		where: [["is_enable", 1]],
	};
	if (typeof query !== "undefined" && Object.keys(query).length > 0) {
		data.where_in = [];

		//search string
		if (query.search && query.search !== "") {
			data.query = {
				value: "+*" + query.search.replace(/\s+/g, "* +*") + "*",
				fields: ["name"],
			};
		}

		//category and subcategory
	}

	POST(
		SERACH_STORE,
		data,
		function (data) {
			if (data.status && data.status) {
				callback(data.data);
			} else {
				callback(false);
			}
		},
		token
	);
};
