import { toast } from "react-toastify";
import { WISH_LIST } from "../../constants/api";
import { SOMETHING_WRONG } from "../../lang/en/messages";
import { POST } from "../api";

export const wishlist = async (owner_id, callback, token = null) => {
	var data = {
		fields: [
			"id",
			"uuid",
			"owner_id",
			"name",
			"description",
			"condition",
			"category_id",
			"brand_id",
			"price",
			"stock",
			"is_taxable",
			"cost",
			"discount",
			"discount_unit",
			"salePrice",
			"age",
			"age_unit",
			"moq",
			"total_qty",
			"um",
			"msrp",
			"mpn",
			"weight",
			"weight_unit",
			"length",
			"length_unit",
			"width",
			"width_unit",
			"package_type",
			"upc",
			"part_number",
			"sku",
			"last_sku",
			"type",
			"publish_date",
			"is_active",
			"reviews_total",
			"reviews_avg",
			"options",
			"variants",
			"created_at",
		],
		with: ["item.owner", "item.images", "item.documents", "item.category", "item.brand", "item.bulk_savings"],
		limit: 20,
		orderby: { created_at: "DESC" },
		owner_id: owner_id,
	};
	POST(
		WISH_LIST,
		data,
		function (data) {
			if (data.http_error) {
				toast.error(data.error);
			} else if (data.errors) {
				toast.error(data.errors.seller_detail_id[0]);
			} else if (data.status && data.status) {
				callback(data.data);
				// toast.success(data.message);
			} else {
				toast.error(SOMETHING_WRONG);
			}
		},
		token
	);
};
