import { GET_DEAL_LISTINGS } from "../../constants/api";
import ApiService from "../api-service";
import { buildQueryParams } from "../../utils";
const filterSolarDeals = async (search) => {
	if (search) {
		const response = await ApiService.get(`${GET_DEAL_LISTINGS}?${buildQueryParams(search)}`);
		if (response && response.success) {
			let { pagination, data: deals } = response;
			return { pagination, deals };
		}
		throw new Error("Deals Not Found!");
	}
};
export default filterSolarDeals;
