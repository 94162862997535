import { POST } from "../../api";
import { toast } from "react-toastify";
import { SOMETHING_WRONG } from "../../../lang/en/messages";
import { STORE_FRONT_FAVOURITE_LIST } from "../../../constants/api";

export const favouriteStoreList = async (callback, token = null) => {
	POST(
		STORE_FRONT_FAVOURITE_LIST,
		{},
		function (data) {
			if (data.http_error) {
				toast.error(data.error);
			} else if (data.errors) {
				toast.error(data.errors.seller_detail_id[0]);
			} else if (data.status && data.status) {
				callback(data.data);
			} else {
				toast.error(SOMETHING_WRONG);
			}
		},
		token
	);
};
