import {
	UPDATE_LISTING_SIDEBAR,
	REMOVE_LISTING_SIDEBAR,
	ADD_OPEN_DEAL,
	REMOVE_OPEN_DEAL,
	ADD_OPEN_OFFER,
	REMOVE_OPEN_OFFER,
	ADD_CHAT_WITH,
	REMOVE_CHAT_WITH,
} from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	frontEnd: {
		listing: {},
	},
	admin: {},
};

const brokerReducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_LISTING_SIDEBAR:
			return {
				...state,
				frontEnd: {
					...state.frontEnd,
					listing: {
						...state.frontEnd.listing,
						sidebar: action.payload,
					},
				},
			};
		case REMOVE_LISTING_SIDEBAR:
			return {
				...state,
				frontEnd: {
					...state.frontEnd,
					listing: {
						...state.frontEnd.listing,
						sidebar: null,
					},
				},
			};
		case ADD_OPEN_DEAL:
			return {
				...state,
				admin: {
					openDeal: action.payload,
				},
			};
		case REMOVE_OPEN_DEAL:
			return {
				...state,
				admin: {
					openDeal: null,
				},
			};
		case ADD_OPEN_OFFER:
			return {
				...state,
				frontEnd: {
					...state.frontEnd,
					openOffer: action.payload,
				},
			};
		case REMOVE_OPEN_OFFER:
			return {
				...state,
				frontEnd: {
					...state.frontEnd,
					openOffer: null,
				},
			};
		case ADD_CHAT_WITH:
			return {
				...state,
				frontEnd: {
					...state.frontEnd,
					openChatWith: action.payload,
				},
			};
		case REMOVE_CHAT_WITH:
			return {
				...state,
				frontEnd: {
					...state.frontEnd,
					openChatWith: null,
				},
			};
		default:
			return state;
	}
};

const persistConfig = {
	keyPrefix: "Broker-",
	key: "Broker",
	storage,
};

export default persistReducer(persistConfig, brokerReducer);
