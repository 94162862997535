import { WISHLIST } from "../../constants/api";
import ApiService from "../api-service";
const getWishlist = async (token) => {
	const response = await ApiService.get(`${WISHLIST}?page=1&limit=10`, token);
	if (response && response.success) {
		let data = response.data;
		return data;
	}
	return false;
};
export default getWishlist;
