import React, { memo } from "react";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "../../../constants/regex";
import Error from "../../helper/input-field-error";
import { Link } from "react-router-dom";
import { BUYER as message } from "../../../lang/en/messages";

const SignIn = memo(({ onSubmit, forgotPasswordModal }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-group">
				<label>Your email address *</label>
				<input
					type="email"
					className={`form-control ${errors.email && "is-invalid"}`}
					{...register("email", {
						required: true,
						maxLength: 100,
						pattern: EMAIL_REGEX,
					})}
				/>
				{errors.email && <Error message={message.signin.email.reqvalid} />}
			</div>

			<div className="form-group">
				<label>Password *</label>
				<input
					type="password"
					className={`form-control ${errors.password && "is-invalid"}`}
					{...register("password", { required: true, minLength: 8 })}
				/>
				{errors.password && <Error message={message.signin.password.req} />}
			</div>

			<div className="form-footer">
				<button
					type="submit"
					className="btn btn-outline-primary-2">
					<span>LOG IN</span>
					<i className="icon-long-arrow-right"></i>
				</button>

				<div className="custom-control custom-checkbox">
					<input
						type="checkbox"
						className="custom-control-input"
						id="signin-remember-2"
					/>
					{/*<label className="custom-control-label" htmlFor="signin-remember-2">*/}
					{/*Remember Me*/}
					{/*</label>*/}
				</div>

				<Link
					to="#"
					onClick={forgotPasswordModal}
					className="forgot-link">
					Forgot Your Password?
				</Link>
			</div>
		</form>
	);
});

export default SignIn;
