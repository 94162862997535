import React from "react"; // added for JSX

// user
export const ACTIVE = "active";
export const CURRENCY = "$";

// dynamic fields
export const TEXT = "text";
export const NUMERIC = "numeric";
export const BOOLEAN = "boolean";
export const SELECT = "select";

export const FIELDS = {
	TEXT: (
		<input
			type="text"
			name={"test"}
			value=""
		/>
	),
};
