import React, { memo } from "react";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "../../../constants/regex";
import Error from "../../helper/input-field-error";
import { BUYER as message } from "../../../lang/en/messages";

const ForgotPassword = memo(({ onSubmit }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="form-group">
				<label>Your email address *</label>
				<input
					type="email"
					className={`form-control ${errors.email && "is-invalid"}`}
					{...register("email", {
						required: true,
						maxLength: 100,
						pattern: EMAIL_REGEX,
					})}
				/>
				{errors.email && <Error message={message.signin.email.reqvalid} />}
			</div>

			<div className="form-footer">
				<button
					type="submit"
					className="btn btn-outline-primary-2">
					<span>Reset Password</span>
					<i className="icon-long-arrow-right"></i>
				</button>
			</div>
		</form>
	);
});

export default ForgotPassword;
