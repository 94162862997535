import * as api from "../api";
import * as types from "../constants/action-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { USER, USER_PROFILE } from "../constants/local-storage";
import { search } from "../api/categories/search";
import { productSearch } from "../api/items/search";
import { getBrands } from "../api/brands/search";
import { getProfile } from "../api";
import { getCartItems } from "../api/cart/item-search";
import { addCart } from "../api/cart/add-item";
import { removeCartItem } from "../api/cart/remove-item";
import uuid from "react-uuid";
import store from "../store";
import { removeAllItem } from "../api/cart/remove-all-item";
import { GA_addToCart, GA_removeFromCart } from "../components/features/googleAnalytics/index";
import { getStore } from "../api/store-front/get-store";
import { getSellerBrands } from "../api/store-front/seller/get-seller-brands";
import { favouriteStoreList } from "../api/store-front/favourite-store/fav-store-list";
import { wishlist } from "../api/wishlist/wishlist";
import getDealListingSidebar from "../pages/broker/api/broker/get-deal-listing-sidebar";
import getWishlist from "../pages/broker/api/broker/get-wishlist";
import { removeCartItemRevamp } from "../api/cart/remove-item-revamp";
import { getAllCartItem } from "../api/cart/all-cart-item";
/********** login & signup Action ********/
// recieve login
export const receiveLogin = (userData) => ({
	type: types.RECEIVE_LOGIN,
	userData,
});
// get all login
export const userLogin = () => (dispatch) => {
	let userData = {
		data: {},
		isSeller: false,
		isLoggedIn: false,
	};

	let session = localStorage.getItem(USER);
	if (session) {
		session = JSON.parse(session);
		userData.data = session;
		userData.isLoggedIn = true;
		if (userData.data.role.includes("seller")) {
			userData.isSeller = true;
		}
	}

	dispatch(receiveLogin(userData));
	return userData;
};

export const receiveProfile = (profile) => ({
	type: types.RECEIVE_PROFILE,
	profile,
});
export const modifyProfile = (payload) => ({
	type: types.MODIFY_PROFILE,
	payload,
});

export const userProfile = () => (dispatch) => {
	const session = localStorage.getItem(USER_PROFILE);
	const profile = (session && JSON.parse(session)) || {};
	dispatch(receiveProfile(profile));
	return profile;
};

/********** Product Action ********/
// recieve products
export const receiveProducts = (products) => ({
	type: types.RECEIVE_PRODUCTS,
	products,
});
// get all products
export const getAllProducts = () => (dispatch) => {
	productSearch({}, (products) => {
		dispatch(receiveProducts(products));
		return products;
	});
};

/********** Security Questions Action ********/
// recieve securityQuestions
export const receiveSecurityQuestions = (securityQuestions) => ({
	type: types.RECEIVE_SECURITY_QUESTIONS,
	securityQuestions,
});
// get all securityQuestions
export const getAllSecurityQuestions = () => (dispatch) => {
	api.getSecurityQuestions().then((securityQuestions) => {
		dispatch(receiveSecurityQuestions(securityQuestions));
		return securityQuestions;
	});
};

// refresh local storage

export const refreshUnSafe = (current) => ({
	type: types.REFRESH_STORE,
	current,
});

export const refreshStore = (current) => (dispatch) => {
	dispatch(refreshUnSafe(current));
};

/********** Categories Action ********/

export const receiveCategories = (categories) => ({
	type: types.RECEIVE_CATEGORIES,
	categories,
});

// get all categories
export const getAllCategories = () => (dispatch) => {
	search({}, (categories) => {
		dispatch(receiveCategories(categories));
		return categories;
	});
};
export const receiveSellerCategories = (categories) => ({
	type: types.RECEIVE_SELLER_CATEGORIES,
	categories,
});
// get seller categories
export const getSellerCategories = (seller_id) => (dispatch) => {
	search(
		{},
		(categories) => {
			dispatch(receiveSellerCategories(categories));
			return categories;
		},
		seller_id
	);
};
//remove seller categories
export const removeSellerCategories = () => (dispatch) => {
	dispatch({
		type: types.REMOVE_SELLER_CATEGORIES,
	});
};
export const receiveUserProfile = (userProfile) => ({
	type: types.RECEIVE_USER_PROFILE,
	userProfile,
});
export const getUserProfile = (token) => (dispatch) => {
	getProfile(token, (userProfile) => {
		dispatch(receiveUserProfile(userProfile));
		return userProfile;
	});
};

/********** Brands Action ********/

export const receiveBrands = (brands) => ({
	type: types.RECEIVE_BRANDS,
	brands,
});

// get all products
export const getAllBrands = () => (dispatch) => {
	getBrands({}, (brands) => {
		dispatch(receiveBrands(brands));
		return brands;
	});
};
export const receiveSellerBrands = (sellerBrands) => ({
	type: types.RECEIVE_SELLER_BRANDS,
	sellerBrands,
});

// get seller categories
export const getAllSellerBrands = (seller_id) => (dispatch) => {
	getSellerBrands({}, seller_id, (sellerBrands) => {
		dispatch(receiveSellerBrands(sellerBrands));
		return sellerBrands;
	});
};
//remove seller brands
export const removeSellerBrands = () => (dispatch) => {
	dispatch({
		type: types.REMOVE_SELLER_BRANDS,
	});
};

/*********** Modal related Action **********/
// display quickview
export const showQuickViewModal = (product) => ({
	type: types.SHOW_QUICKVIEW,
	product,
});
// close quickview modal
export const closeQuickViewModal = () => ({
	type: types.CLOSE_QUICKVIEW,
});

// for broker admin panel drawer
export const openDrawer = (toggle) => {
	return {
		type: types.CLOSE_DRAWER,
	};
};

// Show Video & Login modal
export const showModal = (modal) => ({
	type: types.SHOW_MODAL,
	modal: modal,
});

// close Video & Login modal
export const closeModal = (modal) => ({
	type: types.CLOSE_MODAL,
	modal: modal,
});

// don't show Newsletter modal
export const removeNewsletterMdoal = (modal) => ({
	type: types.REMOVE_NEWSLETTER,
});

/************ Cart Action **************/
// get all cart item
// export const getAllCartItems = (products, token) => (dispatch) => {
// 	getCartItems(
// 		{},
// 		typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
// 		(cart) => {
// 			dispatch(removeAllFromCart());
// 			let cartItems = [];
// 			for (let i = 0; i < cart.length; i++) {
// 				products.forEach((product) => {
// 					if (product.uuid === cart[i].item_uuid) {
// 						let xProduct = { ...product };
// 						xProduct.bulk_savings.forEach((item) => {
// 							if (cart[i].qty >= item.min_qty && cart[i].qty <= item.max_qty) {
// 								xProduct.price = item.discounted_price;
// 							}
// 						});
// 						cartItems.push({ product: xProduct, qty: cart[i].qty });
// 					}
// 				});
// 			}
// 			dispatch(addAllToCart(cartItems));
// 		},
// 		token
// 	);
// };
export const getAllCartItemsRevamp = (token) => (dispatch) => {
	getAllCartItem(
		{},
		typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
		(cart) => {
			dispatch(removeAllFromCartRevamp());
			cart &&
				cart.data.forEach((item) => {
					let product = item.item;
					if (product.moq <= product.total_qty) {
						let salePrice = product.price;
						let moq = product.moq;
						let condition = product.condition.charAt(0).toUpperCase() + product.condition.slice(1);
						let bulkSavings = [];

						if (product.scalling_price) {
							bulkSavings = product.bulk_savings;
						}

						if (parseInt(product.discount) > 0 && product.discount && product.discount_unit === "percent") {
							salePrice = product.price - (product.price / 100) * product.discount;
						} else if (parseInt(product.discount) > 0 && product.discount && product.discount_unit === "fixed") {
							salePrice = product.price - product.discount;
						} else if (!!product.scalling_price) {
							(product.bulk_savings || []).forEach((value) => {
								if (item.qty >= value.min_qty && item.qty <= value.max_qty) {
									salePrice = value.discounted_price;
								}
							});
						}
						let xproduct = {
							id: product.id,
							name: product.name,
							price: salePrice,
							salePrice: product.price,
							pictures: product.images,
							imagePlaceholder: "assets/images/products/shop/sm-1.jpg",
							shortDesc: product.description,
							stock: product.total_qty,
							category: [product.category ? product.category.name : ""],
							brands: [product.brand ? product.brand.name : ""],
							ratings: product.reviews_total,
							reviews: product.reviews_total,
							featured: true,
							age: product.age,
							age_unit: product.age_unit,
							brand_id: product.brand_id,
							category_id: product.category_id,
							main_category: (product.category && product.category.parent_id) || 0,
							condition: condition,
							cost: product.cost,
							created_at: product.created_at,
							deleted_at: product.deleted_at,
							discount_unit: product.discount_unit,
							discount: product.discount,
							height: product.height,
							height_unit: product.height_unit,
							is_active: product.is_active,
							is_taxable: product.is_taxable,
							last_sku: product.last_sku,
							length: product.length,
							length_unit: product.length_unit,
							moq: (moq && moq) || 1,
							mpn: product.mpn,
							qty: item.qty,
							msrp: product.msrp,
							options: product.options,
							owner_id: product.owner_id,
							package_type: product.package_type,
							part_number: product.part_number,
							publish_date: product.publish_date,
							reviews_avg: product.reviews_avg,
							short_name: product.short_name,
							sku: product.sku,
							tags: product.tags,
							type: product.type,
							um: product.um,
							upc: product.upc,
							shipping_type: product.shipping_type,
							updated_at: product.updated_at,
							uuid: product.uuid,
							variants: product.variant,
							weight: product.weight,
							weight_unit: product.weight_unit,
							width: product.width,
							width_unit: product.width_unit,
							owner: product.owner,
							documents: product.documents,
							bulk_savings: bulkSavings,
							locations: product.locations,
							shipping_cost: item.shipping_cost,
						};
						dispatch({
							type: types.UPDATE_CART_ITEM,
							product: xproduct,
							qty: xproduct.qty,
						});
					}
				});
		},

		token
	);
};

// migrate all cart item to user cart
export const migrateAllCartItems = (cartlist, token) => {
	if (cartlist && cartlist.length > 0) {
		removeAllItem(
			typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
			(result) => {
				if (result) {
					for (let i = 0; i < cartlist.length; i++) {
						const cart = {
							item_uuid: cartlist[i].uuid,
							qty: cartlist[i].qty,
						};

						addCart(cart, (result) => {}, token);
					}
				}
			},
			token
		);
	}
};

// add item to cart revamp
export const addToCartRevamp = (product, qty, token) => (dispatch) => {
	dispatch(setAddCartLoading(product.id));
	const data = {
		item_uuid: product.uuid,
		qty: qty,
		owner_id: typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
	};
	addCart(
		data,
		(result) => {
			if (result.status && result.status) {
				let shipping_cost = result.data && result.data.shipping_cost;
				let locations = result.data && result.data.item && result.data.item.locations;
				dispatch(addToCartNew(product, qty, shipping_cost, locations));
				// GA_addToCart(product, qty);
				toast.success("Item Added to Cart");
			}
		},
		token
	);
};

// update item qty to cart revamp
export const updateCartQtyRevamp = (product, qty, token) => {
	const data = {
		item_uuid: product.uuid,
		qty: qty,
		owner_id: typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
	};

	addCart(
		data,
		(result) => {
			if (result.status && result.status) {
				store.dispatch(changeQtyRevamp(product.id, qty, result.data && result.data.shipping_cost));
			}
		},
		token
	);
};

// update item qty to cart
export const updateCartQty = (product, qty, token) => {
	const data = {
		item_uuid: product.uuid,
		qty: qty,
		owner_id: typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
	};

	addCart(
		data,
		(result) => {
			if (result.status && result.status) {
				store.dispatch(changeQty(product.id, qty));
			}
		},
		token
	);
};

// add item to cart : typical action
export const addToCartUnsafe = (product, qty) => ({
	type: types.ADD_TO_CART,
	product,
	qty,
});
// add item to cart : typical action
export const addToCartNew = (product, qty, shipping_cost, locations) => {
	return {
		type: types.ADD_TO_CART_REVAMP,
		product,
		qty,
		shipping_cost,
		locations,
	};
};

// add item to cart : typical action
export const addAllToCart = (cartItems) => ({
	type: types.GET_CART,
	cartItems,
});
// add item to cart : typical action all to cart in cart revamp
export const addAllToCartRevamp = (cartItems) => ({
	type: types.GET_ALL_CART_ITEMS,
	cartItems,
});
// remove item from wishlist
export const removeFromWishlist = (productId) => (dispatch) => {
	toast.error("Item removed from Wishlist");
	dispatch({
		type: types.REMOVE_FROM_WISHLIST,
		productId,
	});
};

// remove item from generate uuid
export const getUuid = () => {
	if (localStorage.getItem("uuid") == null || typeof localStorage.getItem("uuid") === "undefined") {
		localStorage.setItem("uuid", uuid());
	}
};

// add item to cart from wishlist
export const addToCartFromWishlist = (product, qty, token) => (dispatch) => {
	const data = {
		item_uuid: product.uuid,
		qty: qty,
		owner_id: token ? "" : localStorage.getItem("uuid"),
	};
	addCart(
		data,
		(result) => {
			if (result) {
				dispatch({
					type: types.REMOVE_FROM_WISHLIST,
					productId: product.id,
				});
				dispatch(addToCartUnsafe(product, product.moq));

				toast.success("Item added to Cart");
			}
		},
		token
	);
};

// remove item from cart
export const removeFromCart = (product, token) => (dispatch) => {
	removeCartItem(
		typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
		product.uuid,
		(result) => {
			if (result) {
				dispatch({
					type: types.REMOVE_FROM_CART,
					productId: product.id,
				});
				GA_removeFromCart(product);
				toast.error("Item removed from Cart");
			}
		},
		token
	);
};
export const setItemLoading = (itemId) => ({
	type: types.SET_ITEM_LOADING,
	itemId,
});

export const setAddCartLoading = (itemId) => ({
	type: types.SET_CART_LOADING,
	itemId,
});

export const removeFromCartRevamp = (product, token) => (dispatch) => {
	dispatch(setItemLoading(product.id));
	removeCartItemRevamp(
		typeof token !== "undefined" ? "" : localStorage.getItem("uuid"),
		product.uuid,
		(result) => {
			if (result) {
				dispatch({
					type: types.REMOVE_FROM_CART_REVAMP,
					productId: product.id,
				});
				GA_removeFromCart(product);
				toast.error("Item removed from Cart");
			}
		},
		token
	);
};
// remove all item from cart
export const removeAllFromCart = () => (dispatch) => {
	dispatch({
		type: types.REFRESH_STORE,
	});
};
// remove all item from cart revamp
export const removeAllFromCartRevamp = () => (dispatch) => {
	dispatch({
		type: types.REFRESH_STORE_REVAMP,
	});
};

// change item's qty
export const changeQty = (productId, qty) => ({
	type: types.CHANGE_QTY,
	productId,
	qty,
});

export const changeQtyRevamp = (productId, qty, shipping_cost) => ({
	type: types.CHANGE_QTY_REVAMP,
	productId,
	qty,
	shipping_cost,
});

// change shipping method
export const changeShipping = (shipping) => ({
	type: types.CHANGE_SHIPPING,
	shipping,
});

/*********** StoreFront Action *********/
// add item to favourite Store
export const getStoreFrontFavList = (token) => (dispatch) => {
	favouriteStoreList((data) => {
		dispatch({
			type: types.RECEIVE_STORE_FRONT_FAV_LIST,
			store: data,
		});
	}, token);
};

/*********** Wishlist Action *********/

// add item to wishlist
export const toggleWishlist = (product) => (dispatch) => {
	dispatch(toggleWishlistUnsafe(product));
};

// add item to wishlist : typical action
export const toggleWishlistUnsafe = (product) => ({
	type: types.TOGGLE_WISHLIST,
	product,
});

export const getWishList = (owner_id, token) => (dispatch) => {
	wishlist(
		owner_id,
		// product_uuid,
		(data) => {
			dispatch({
				type: types.TOGGLE_WISHLIST,
				list: data ? data : [],
			});
		},
		token
	);
	// dispatch( toggleWishlistUnsafe( product ) )
};
export const getDealWishList = (token) => async (dispatch) => {
	const data = await getWishlist(token);
	dispatch({
		type: types.TOGGLE_DEAL,
		dealList: data ? data : [],
	});

	// dispatch( toggleWishlistUnsafe( product ) )
};

/************* Compare Action ***********/
// add to comparelist
export const addToCompare = (product) => (dispatch) => {
	toast.success("Item added to Compare");
	dispatch(addToCompareUnsafe(product));
};

export const addToCompareUnsafe = (product) => ({
	type: types.ADD_TO_COMPARE,
	product,
});

// remove all items from cartlist
export const removeFromCompare = (productId) => (dispatch) => {
	toast.success("Compare item removed");
	dispatch(removeFromCompareUnsafe(productId));
};

export const removeFromCompareUnsafe = (productId) => ({
	type: types.REMOVE_FROM_COMPARE,
	productId,
});

// reset cartlist with intialstate
export const resetCompare = () => ({
	type: types.RESET_COMPARE,
});

/************** Filter Action ***********/
//set filter
export const setFilter = (data) => (dispatch) => {
	dispatch({
		type: types.SET_FILTER,
		payload: data,
	});
};

// set order to sort
export const filterSort = (sortBy) => (dispatch) => {
	dispatch({
		type: types.SORT_BY,
		sortBy,
	});
};

// set price range to get suitable products
export const filterPrice = (range) => (dispatch) => {
	dispatch({
		type: types.PRICE_FILTER,
		range,
	});
};

// add/remove sub category to get suitable products
export const toggleCategoryFilter = (category) => (dispatch) => {
	dispatch({
		type: types.CATEGORY_FILTER,
		category,
	});
};

// add/remove main category to get suitable products
export const toggleMainCategoryFilter = (main_category) => (dispatch) => {
	dispatch({
		type: types.MAIN_CATEGORY_FILTER,
		main_category,
	});
};

// custom header search
export const searchProductsFilter = (searchString) => (dispatch) => {
	dispatch({
		type: types.SEARCH_STRING_FILTER,
		searchString,
	});
};

// add/remove product size to get suitable products
export const toggleSizeFilter = (size) => (dispatch) => {
	dispatch({
		type: types.SIZE_FILTER,
		size,
	});
};

// add/remove color to get suitable products
export const toggleColorFilter = (color) => (dispatch) => {
	dispatch({
		type: types.COLOR_FILTER,
		color,
	});
};

// add/remove brand to get suitable products
export const toggleBrandFilter = (brand) => (dispatch) => {
	dispatch({
		type: types.BRAND_FILTER,
		brand,
	});
};

// add/remove shipping type to get suitable products
export const toggleShippingTypeFilter = (shippingType) => (dispatch) => {
	dispatch({
		type: types.SHIPPING_TYPE_FILTER,
		shippingType,
	});
};

// add/remove condition type to get suitable products
export const toggleConditionFilter = (condition) => (dispatch) => {
	dispatch({
		type: types.CONDITION_FILTER,
		condition,
	});
};

// add/remove rating to get suitable products
export const toggleRatingFilter = (rating) => (dispatch) => {
	dispatch({
		type: types.RATING_FILTER,
		rating,
	});
};

// reset filter with intialstate
export const resetFilter = () => (dispatch) => {
	dispatch({
		type: types.RESET_FILTER,
	});
};

/************** Newsletter Modal ************/

// hide newsletter modal in homepage
export const hideNewsletterModal = () => ({
	type: types.HIDE_NEWSLETTER_MODAL,
});

// add edit product save product details
export const setAddEDitProduct = (product_details) => (dispatch) => {
	dispatch({
		type: types.ADD_EDIT_PRODUCT,
		product_details,
	});
};
// add edit product remove product details
export const removeAddEDitProduct = () => (dispatch) => {
	dispatch({
		type: types.REMOVE_PRODUCT_DETAILS,
	});
};

/************** StoreFront ************/
// add project for edit or update mode
export const setSelectedProject = (selectedProject) => (dispatch) => {
	dispatch({
		type: types.EDIT_PROJECT,
		selectedProject,
	});
};
export const setRemoveSelectedProject = () => (dispatch) => {
	dispatch({
		type: types.REMOVE_EDIT_PROJECT,
	});
};
//receive store
export const getSellerStore = (seller_id, editAble) => (dispatch) => {
	getStore(seller_id, editAble, (data) => {
		dispatch({
			type: types.RECEIVE_STORE,
			store: { ...data[0] },
		});
	});
};
export const removeSellerStore = () => (dispatch) => {
	dispatch({
		type: types.REMOVE_STORE,
	});
};
export const updateSellerStore = (data) => (dispatch) => {
	dispatch({
		type: types.UPDATE_STORE,
		data: data,
	});
};

//broker
export const updateDealListingSidebar = (category) => (dispatch) => {
	getDealListingSidebar(category)
		.then((data) => {
			dispatch({
				type: types.UPDATE_LISTING_SIDEBAR,
				payload: data,
			});
		})
		.catch((err) => {
			toast.error(err.message);
		});
};
export const removeDealListingSidebar = (data) => (dispatch) => {
	dispatch({
		type: types.REMOVE_LISTING_SIDEBAR,
	});
};
export const addOpenDeal = (dealId) => (dispatch) => {
	dispatch({
		type: types.ADD_OPEN_DEAL,
		payload: dealId,
	});
};
export const removeOpenDeal = (data) => (dispatch) => {
	dispatch({
		type: types.REMOVE_OPEN_DEAL,
	});
};
// open offer
export const addOpenOffer = (listing_id) => (dispatch) => {
	dispatch({
		type: types.ADD_OPEN_OFFER,
		payload: listing_id,
	});
};
export const removeOpenOffer = (data) => (dispatch) => {
	dispatch({
		type: types.REMOVE_OPEN_OFFER,
	});
};
// chat with
export const addChatWith = (chatWith) => (dispatch) => {
	dispatch({
		type: types.ADD_CHAT_WITH,
		payload: chatWith,
	});
};
export const removeChatWith = (data) => (dispatch) => {
	dispatch({
		type: types.REMOVE_CHAT_WITH,
	});
};

export const setIsGuestUser = (isGuestUser) => (dispatch) => {
	dispatch({
		type: types.SET_IS_GUEST_USER,
		payload: isGuestUser,
	});
};
