import { POST } from "../api";
import { CLIENT_LOGOUT } from "../../constants/api";
import { USER, USER_PROFILE } from "../../constants/local-storage";
import store from "../../store";
import { userLogin, userProfile } from "../../actions";
import { GA_logout } from "../../components/features/googleAnalytics/index";
export const signout = (token, returnUrl) => {
	POST(
		CLIENT_LOGOUT,
		{},
		function (data) {
			if (data.status && data.status) {
				//let history = useHistory();
				//history.push('/');
				localStorage.removeItem(USER);
				localStorage.removeItem(USER_PROFILE);
				GA_logout();
				store.dispatch(userLogin());
				store.dispatch(userProfile());

				window.location.href = returnUrl;
			} else {
				// toast.error(SOMETHING_WRONG);
				localStorage.removeItem(USER);
				localStorage.removeItem(USER_PROFILE);
				store.dispatch(userLogin());
				store.dispatch(userProfile());
			}
		},
		token
	);
};
