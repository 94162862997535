import { RECEIVE_PRODUCTS, SHOW_QUICKVIEW, CLOSE_QUICKVIEW } from "../constants/action-types";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	products: [],
	productDetail: [],
	quickView: false,
	productuuid: null,
};

const productReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECEIVE_PRODUCTS:
			return {
				...state,
				products: action.products,
			};

		case SHOW_QUICKVIEW:
			if (action.product.length > 0) {
				return {
					...state,
					quickView: true,
					productDetail: action.product,
				};
			}
			break;

		case CLOSE_QUICKVIEW:
			return { ...state, quickView: false };

		default:
			return state;
	}
};
const persistConfig = {
	keyPrefix: "molla-",
	key: "products",
	storage,
};

export default persistReducer(persistConfig, productReducer);
