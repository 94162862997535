import {
	ADD_TO_CART,
	ADD_TO_CART_REVAMP,
	REMOVE_FROM_CART,
	REMOVE_FROM_CART_REVAMP,
	CHANGE_QTY,
	CHANGE_QTY_REVAMP,
	CHANGE_SHIPPING,
	REFRESH_STORE,
	REMOVE_ALL_FROM_CART,
	GET_ALL_CART_ITEMS,
	GET_CART,
	UPDATE_CART_ITEM,
	REFRESH_STORE_REVAMP,
	SET_ITEM_LOADING,
	SET_CART_LOADING,
} from "../constants/action-types";
import { findIndex } from "../utils";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	cart: [],
	cart_revamp: [],
	shipping: "free",
	loadingItems: {},
	loadingCart: {},
};

function cartReducer(state = initialState, action) {
	switch (action.type) {
		case ADD_TO_CART:
			const productId = action.product.id;

			if (findIndex(state.cart, (product) => product.id === productId) !== -1) {
				const cart = state.cart.reduce((cartAcc, product) => {
					if (product.id === productId) {
						cartAcc.push({
							...action.product,
							qty: parseInt(action.qty),
							sum:
								(action.product.discount && action.product.salePrice < action.product.price
									? action.product.salePrice
									: action.product.price) * parseInt(action.qty),
						}); // Increment qty
					} else {
						cartAcc.push(...action.product);
					}
					return cartAcc;
				}, []);

				return { ...state, cart };
			}

			return {
				...state,
				cart: [
					...state.cart,
					{
						...action.product,
						qty: action.qty,
						sum:
							(action.product.discount && action.product.salePrice < action.product.price
								? action.product.salePrice
								: action.product.price) * action.qty,
					},
				],
			};
		case ADD_TO_CART_REVAMP:
			const proId = action.product.id;
			if (findIndex(state.cart_revamp, (product) => product.id === proId) !== -1) {
				const cart_revamp = state.cart_revamp.reduce((cartAcc, product) => {
					if (product.id === proId) {
						// If the product is already in the cart, update its quantity
						cartAcc.push({
							...product,
							qty: product.qty + parseInt(action.qty),
							sum:
								(product.discount && product.salePrice < product.price ? product.salePrice : product.price) *
								(product.qty + parseInt(action.qty)),
						});
					} else {
						// If the product is not in the cart, add it as it is
						cartAcc.push(product);
					}
					return cartAcc;
				}, []);

				return {
					...state,
					cart_revamp,
					loadingCart: {
						...state.loadingCart,
						[proId]: false,
					},
				};
			}
			return {
				...state,
				cart_revamp: [
					...state.cart_revamp,
					{
						...action.product,
						qty: action.qty,
						shipping_cost: action.shipping_cost,
						locations: action.locations,
						sum:
							(action.product.discount && action.product.salePrice < action.product.price
								? action.product.salePrice
								: action.product.price) * action.qty,
					},
				],
				loadingCart: {
					...state.loadingCart,
					[proId]: false,
				},
			};

		case REMOVE_FROM_CART:
			return {
				...state,
				cart: state.cart.filter((item) => item.id !== action.productId),
			};
		case SET_ITEM_LOADING:
			return {
				...state,
				loadingItems: {
					...state.loadingItems,
					[action.itemId]: true,
				},
			};
		case SET_CART_LOADING:
			return {
				...state,
				loadingCart: {
					...state.loadingCart,
					[action.itemId]: true,
				},
			};

		case REMOVE_FROM_CART_REVAMP:
			return {
				...state,
				cart_revamp: state.cart_revamp.filter((item) => item.id !== action.productId),
				loadingItems: {
					...state.loadingItems,
					[action.productId]: false,
				},
			};

		case REMOVE_ALL_FROM_CART:
			return {
				...state,
				cart: [],
			};

		case GET_CART:
			let products = [];
			for (let i = 0; i < action.cartItems.length; i++) {
				const item = action.cartItems[i];
				products.push({
					...item.product,
					qty: item.qty,
					sum:
						(item.product.discount && item.product.salePrice < item.product.price
							? item.product.salePrice
							: item.product.price) * item.qty,
				});
			}
			return {
				...state,
				cart: products,
			};
		case GET_ALL_CART_ITEMS:
			let cartProducts = [];
			for (let i = 0; i < action.cartItems.length; i++) {
				const item = action.cartItems[i];
				cartProducts.push({
					...item.product,
					qty: item.qty,
					sum:
						(item.product.discount && item.product.salePrice < item.product.price
							? item.product.salePrice
							: item.product.price) * item.qty,
				});
			}
			return {
				...state,
				cart: cartProducts,
			};

		case CHANGE_QTY:
			const cart = state.cart.reduce((cartAcc, product) => {
				if (product.id === action.productId) {
					cartAcc.push({
						...product,
						qty: action.qty,
						sum:
							(product.discount && product.salePrice < product.price ? product.salePrice : product.price) * action.qty,
					}); // Increment qty
				} else {
					cartAcc.push(product);
				}
				return cartAcc;
			}, []);

			return { ...state, cart };

		case CHANGE_QTY_REVAMP:
			const cart_revamp = state.cart_revamp.reduce((cartAcc, product) => {
				if (product.id === action.productId) {
					cartAcc.push({
						...product,
						qty: action.qty,
						shipping_cost: action.shipping_cost ? action.shipping_cost : product.shipping_cost,
						sum:
							(product.discount && product.salePrice < product.price ? product.salePrice : product.price) * action.qty,
					}); // Increment qty
				} else {
					cartAcc.push(product);
				}
				return cartAcc;
			}, []);

			return { ...state, cart_revamp };

		case REFRESH_STORE:
			return { ...state, cart: [], shipping: "free" };

		case REFRESH_STORE_REVAMP:
			return { cart_revamp: [] };

		case CHANGE_SHIPPING:
			return { ...state, shipping: action.shipping };
		case UPDATE_CART_ITEM:
			return {
				...state,
				cart_revamp: [
					...state.cart_revamp,
					{
						...action.product,
						qty: action.qty,
						sum:
							(action.product.discount && action.product.salePrice < action.product.price
								? action.product.salePrice
								: action.product.price) * action.qty,
					},
				],
			};

		default:
			return state;
	}
}

const persistConfig = {
	keyPrefix: "molla-",
	key: "cartlist",
	storage,
};

export default persistReducer(persistConfig, cartReducer);
