import { TOGGLE_DEAL, TOGGLE_WISHLIST } from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
	list: [],
	dealList: [],
};

function wishlistReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_WISHLIST:
			return {
				...state,
				list: action.list,
			};
		case TOGGLE_DEAL:
			return {
				...state,
				dealList: action.dealList,
			};

		default:
	}
	return state;
}

const persistConfig = {
	keyPrefix: "molla-",
	key: "wishlist",
	storage,
};

export default persistReducer(persistConfig, wishlistReducer);
