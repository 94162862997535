import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-app-polyfill/ie11";
import { PersistGate } from "redux-persist/integration/react";
// import store
import store, { persistor } from "./store";
// import action
import { refreshStore, getUuid } from "./actions";
// import routes
import AppRoute from "./routes";
// import Utils
import { initFunctions } from "./utils";
import LoadingOverlay from "./components/features/loading-overlay";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./config";
import { productSearch } from "./api/items/search";
import * as types from "./constants/action-types";

export function Root() {
	initFunctions();
	productSearch({}, (products) => {
		store.dispatch({ type: types.RECEIVE_PRODUCTS, products });
		return products;
	});
	getUuid();
	useEffect(() => {
		if (store.getState().modal.current !== 14) {
			store.dispatch(refreshStore(14));
		}
	}, []);

	return (
		<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
			<Provider store={store}>
				<PersistGate
					persistor={persistor}
					loading={<LoadingOverlay />}>
					<BrowserRouter basename={"/"}>
						<AppRoute />
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</GoogleOAuthProvider>
	);
}

ReactDOM.render(<Root />, document.getElementById("root"));
